import React, { useState } from "react"
import SlideToggle from "react-slide-toggle"
import InputRange from "react-input-range"
import "react-input-range/lib/css/index.css"

import Layout from "../templates/Page"
import Header from "../components/ContentBuilder/Header"
import Dropdown from "../components/ContentBuilder/Dropdown"
import FilterResult from "../components/ContentBuilder/FilterResult"
import CategoryDropdown from "../components/ContentBuilder/CategoryDropdown"
import Subscribe from "../components/Footer/subscribe"

const BrowserHomeDesignCategoryPage = () => {
  const [showModal, setShowModal] = useState(true)

  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <div className="d-none d-sm-flex justify-content-between align-items-center wrap-section mt-30 mb-30 border-bottom-cell">
          <div className="product-name-title">Browser Home Design</div>
        </div>
        <div className="explore-functional mt-15">
          <Dropdown title="SORT" />
          <CategoryDropdown title="CATEGORY" />

          <div className="menu-margin search-box">
            <input className="filter-text" placeholder="SEARCH" />
            <div className="filter-icon">
              <img src="/img/explore/search.svg" />
            </div>
          </div>
        </div>
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section row py-5">
          <div className="col-lg-3">
            <div className="filter-modal-container filter-modal-container-custom">

              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        LAND SIZE
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="input-range-container">
                        <InputRange
                          formatLabel={value => `$${value}`}
                          maxValue={1000}
                          minValue={0}
                          step={100}
                          value={10}
                          onChange={value => {
                            // setValue(value)
                            // props.filterPrice(value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
            </div>
            <div className="filter-modal-container-custom mt-15">
              <div className="d-sm-flex align-items-center mt-15 mb-15 border-bottom-cell">
                <div className="browser-title">My address</div>
              </div>
              <div className="browser-sub-title">
                Lot 28 - Melon Circuit, Gregory Hills. 2567
              </div>
            </div>
            <div className="filter-modal-container-custom mt-15">
              <div className="browser-map">
                <div className="browser-map-button">
                  <div className="browser-title"> MAP</div>
                </div>
              </div>
              <div className="browser-title border-bottom mt-15">
                Change Address
              </div>
            </div>

            <div className="filter-modal-container filter-modal-container-custom  mt-15">
              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        BEDROOM
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>1</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>2</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>3</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>4</div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        BATHROOM
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Contemporary </div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>1</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>2</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>3</div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>

              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        AMENITY
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Home Office </div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Media Room</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Infant Room</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Living Room</div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        GARAGE
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>1</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>2</div>
                        <input type="checkbox" />
                      </div>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>3</div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        PRICE
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="input-range-container">
                        <InputRange
                          formatLabel={value => `$${value}`}
                          maxValue={1000}
                          minValue={0}
                          step={100}
                          onChange={value => {}}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
              <SlideToggle>
                {({ toggle, setCollapsibleElement }) => (
                  <div className="widget widget-collapsible">
                    <h3 className="widget-title">
                      <div
                        className="wiget-title-name"
                        onClick={e => {
                          toggle(e)
                          e.preventDefault()
                        }}
                      >
                        LAND SIZE
                      </div>
                    </h3>
                    <div className="widget-body" ref={setCollapsibleElement}>
                      <div className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <div>Width </div>
                        <input type="checkbox" />
                      </div>
                    </div>
                  </div>
                )}
              </SlideToggle>
            </div>
          </div>
          <div className="col-6 col-sm-9 no-padding-mobile mobile-scroll">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="browser-home-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="browser-home-title browser-border-bottom pb-2 pt-2">
                    Pasadena 1
                  </div>
                  <div className="browser-home-size  browser-border-bottom pb-2 pt-2">
                    <strong>Size:</strong> <span>265sqm | 28 squares</span>
                  </div>
                  <div className="browser-home-size  browser-border-bottom">
                    Price
                  </div>
                  <div className="map"></div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="browser-home-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="browser-home-title browser-border-bottom pb-2 pt-2">
                    Pasadena 1
                  </div>
                  <div className="browser-home-size  browser-border-bottom pb-2 pt-2">
                    <strong>Size:</strong> <span>265sqm | 28 squares</span>
                  </div>
                  <div className="browser-home-size  browser-border-bottom">
                    Price
                  </div>
                  <div className="map"></div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="browser-home-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="browser-home-title browser-border-bottom pb-2 pt-2">
                    Pasadena 1
                  </div>
                  <div className="browser-home-size  browser-border-bottom pb-2 pt-2">
                    <strong>Size:</strong> <span>265sqm | 28 squares</span>
                  </div>
                  <div className="browser-home-size  browser-border-bottom">
                    Price
                  </div>
                  <div className="map"></div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="browser-home-cart border pl-4 pr-4 pt-2 pb-5 mb-5">
                  <div className="browser-home-title browser-border-bottom pb-2 pt-2">
                    Pasadena 1
                  </div>
                  <div className="browser-home-size  browser-border-bottom pb-2 pt-2">
                    <strong>Size:</strong> <span>265sqm | 28 squares</span>
                  </div>
                  <div className="browser-home-size  browser-border-bottom">
                    Price
                  </div>
                  <div className="map"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex w-100 justify-content-center">
            <button className="btn border text-link mb-5 browser-sub-title border-bottom">
              LOAD MORE
            </button>
          </div>
        </div>

      </div>
      <Subscribe></Subscribe>
      {showModal && (
        <div className="c-browser-home-design">
          <div className="browser-home-design-form">
            <div className="title-text">
              We can ensure the homes your are looking at <br />
              are the perfect fit for your block.
            </div>

            <div className="title-text">
              Create a free account to add your block of land.
            </div>

            <div className="sub-title-text">
              Your address is subject to our Privacy Policy, and it will only be
              used to <br />
              show homes that may fit your block.
            </div>

            <div className="d-flex justify-content-center button-wrap">
              <button
                className="browser-home-design-btn"
                onClick={() => setShowModal(false)}
              >
                Create Account
              </button>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default BrowserHomeDesignCategoryPage
