import React, { useCallback } from "react"
const FilterItem = ({ filter, removeItem }) => {
  const removeItemHandler = useCallback(() => {
    removeItem(filter)
  }, [filter, removeItem])

  return (
    <li>
      <div className="filter-item">
        <span className="mr-2">{filter.label}</span>
        <button type="button" className="filter-icon" onClick={removeItemHandler}>
          <svg width="11" height="11"><use xlinkHref="/img/icons.svg#icon-times"></use></svg>
        </button>
      </div>
    </li>
  )
}

export default FilterItem
